.MediaInput__Message {
  margin-top: 0 !important;
  margin-bottom: 0.5rem !important; }

.MediaInput__PreviewImage {
  max-height: 500px;
  margin-bottom: 0.5rem; }
  .MediaInput__PreviewImage:hover {
    opacity: 0.8; }

.MediaInput__PreviewVideo {
  display: block;
  margin-bottom: 0.5rem !important; }
