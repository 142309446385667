.ArticleDescriptionEditor {
  overflow: hidden; }
  .ArticleDescriptionEditor .mce-content-body {
    border-radius: 0.28571429rem;
    border-width: 0;
    min-height: 25rem;
    padding: 1rem;
    margin: -1rem;
    outline: none; }
    .ArticleDescriptionEditor .mce-content-body .article-internal-link {
      margin: 20px auto !important;
      width: calc(100% - 12px); }
  .ArticleDescriptionEditor .mce-offscreen-selection {
    display: none !important; }
  .ArticleDescriptionEditor .mce-object-iframe {
    display: block;
    text-align: center;
    border-style: none;
    overflow-x: auto;
    overflow-y: hidden; }
  .ArticleDescriptionEditor .device-dependent-char {
    background-color: #ffebee;
    color: #f44336; }
  .ArticleDescriptionEditor .ui.transition.message.toast {
    display: flex !important;
    width: fit-content;
    position: fixed;
    top: 4.5rem;
    right: 360px;
    z-index: 1;
    margin-top: 0; }
    .ArticleDescriptionEditor .ui.transition.message.toast i.icon {
      font-size: 1.5em; }

[class^='mce-i-im-'],
[class*=' mce-i-im-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'IcoMoon-Free' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mce-i-im-blockquote-cite:before {
  content: '\e978'; }

.mce-i-im-link-button:before {
  content: '\ea53'; }

.mce-i-im-link-button-accent:before {
  content: '\e90b'; }

.mce-i-im-link-button-accent-1:before, .mce-i-im-link-button-accent-2:before, .mce-i-im-link-button-accent-3:before, .mce-i-im-link-button-accent-4:before, .mce-i-im-link-button-accent-5:before, .mce-i-im-link-button-accent-6:before, .mce-i-im-link-button-accent-7:before, .mce-i-im-link-button-accent-8:before, .mce-i-im-link-button-accent-9:before, .mce-i-im-link-button-accent-10:before, .mce-i-im-link-button-accent-11:before, .mce-i-im-link-button-accent-12:before, .mce-i-im-link-button-accent-13:before {
  content: '\e90b'; }

.mce-i-im-link-button-accent-1:before {
  color: #022458; }

.mce-i-im-link-button-accent-2:before {
  color: #da789f; }

.mce-i-im-link-button-accent-3:before {
  color: #66a079; }

.mce-i-im-link-button-accent-4:before {
  color: #96c7e6; }

.mce-i-im-link-button-accent-5:before {
  color: #ca0b27; }

.mce-i-im-link-button-accent-6:before {
  color: #aaaaaa; }

.mce-i-im-link-button-accent-7:before {
  color: #ffcc1a; }

.mce-i-im-link-button-accent-8:before {
  color: #6fbbbc; }

.mce-i-im-link-button-accent-9:before {
  color: #8d784d; }

.mce-i-im-link-button-accent-10:before {
  color: #c8358d; }

.mce-i-im-link-button-accent-11:before {
  color: #3bc985; }

.mce-i-im-link-button-accent-12:before {
  color: #fab650; }

.mce-i-im-link-button-accent-13:before {
  color: #c72424; }

.mce-i-im-sns-embed-twitter:before {
  content: '\ea96'; }

.mce-i-im-sns-embed-facebook:before {
  content: '\ea91'; }

.mce-i-im-sns-embed-instagram:before {
  content: '\ea92'; }

.mce-i-im-sns-embed-pinterest:before {
  content: '\ead1'; }

.mce-tinymce-inline.mce-floatpanel[data-trill-mce-selection-toolbar-disabled='true'] {
  visibility: hidden !important;
  transition: none; }

.mce-tinymce-inline.mce-floatpanel[data-trill-mce-selection-toolbar-disabled='false'] {
  visibility: visible;
  transition: visibility 0.1s; }
