.article {
  position: relative;
  width: 100%;
  height: 100%; }
  .article__form {
    width: 100%;
    height: 100%; }
  .article__main {
    position: relative;
    padding-right: 360px;
    height: 100%;
    max-height: 100vh;
    overflow: auto; }
    @media (max-width: 1024px) {
      .article__main {
        padding-right: 0; } }
    .article__main__header.ui.segment {
      position: fixed;
      width: 100%;
      height: 4.5rem;
      top: 0;
      left: 0;
      z-index: 2;
      border-radius: 0;
      margin: 0;
      padding-right: calc(360px + 1rem);
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 320px; }
      @media (max-width: 1024px) {
        .article__main__header.ui.segment {
          padding-right: 1rem; } }
      @media (min-width: 1025px) {
        .article__main__header.ui.segment .ui.grid .column.fourteen.wide {
          width: 70% !important; } }
      @media (min-width: 1025px) {
        .article__main__header.ui.segment .ui.grid .column.two.wide {
          width: 30% !important; } }
    .article__main__header h1.ui.header {
      display: flex;
      align-items: center; }
      .article__main__header h1.ui.header > i.icon {
        font-size: 2.5rem;
        line-height: 1;
        margin: 0; }
        @media (max-width: 425px) {
          .article__main__header h1.ui.header > i.icon {
            font-size: 2.25rem; } }
        @media (max-width: 375px) {
          .article__main__header h1.ui.header > i.icon {
            font-size: 2rem; } }
        @media (max-width: 320px) {
          .article__main__header h1.ui.header > i.icon {
            font-size: 1.75rem;
            margin-top: 4px; } }
      .article__main__header h1.ui.header > .content {
        padding: 0;
        font-size: 2rem; }
        @media (max-width: 425px) {
          .article__main__header h1.ui.header > .content {
            font-size: 1.75rem; } }
        @media (max-width: 375px) {
          .article__main__header h1.ui.header > .content {
            font-size: 1.5rem; } }
        @media (max-width: 320px) {
          .article__main__header h1.ui.header > .content {
            font-size: 1.25rem; } }
      .article__main__header h1.ui.header > .ui.label {
        margin: 0 0 0 0.75rem; }
        @media (max-width: 375px) {
          .article__main__header h1.ui.header > .ui.label {
            font-size: 0.75rem; } }
    .article__main__header__menu-button.ui.button {
      display: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      font-size: 2.5rem;
      color: #444; }
      @media (max-width: 1024px) {
        .article__main__header__menu-button.ui.button {
          display: block; } }
      @media (max-width: 425px) {
        .article__main__header__menu-button.ui.button {
          font-size: 2rem; } }
      @media (max-width: 375px) {
        .article__main__header__menu-button.ui.button {
          font-size: 2rem; } }
      @media (max-width: 320px) {
        .article__main__header__menu-button.ui.button {
          font-size: 1.75rem; } }
    @media (max-width: 1024px) {
      .article__main__header__submit-button.ui.button.ui.labeled.icon.right {
        display: none; } }
    .article__main__sub-header {
      display: none;
      width: 100%;
      margin: calc(4.5rem + 1rem) auto 0;
      padding: 0 1rem;
      text-align: right; }
      @media (max-width: 1024px) {
        .article__main__sub-header {
          display: block; } }
      @media (max-width: 1024px) {
        .article__main__sub-header__submit-button.ui.button.ui.labeled.icon {
          min-width: 11em; } }
      @media (max-width: 375px) {
        .article__main__sub-header__submit-button.ui.button.ui.labeled.icon {
          font-size: 0.75em; } }
    .article__main__content {
      width: 100%;
      max-width: 926px;
      padding: 2rem;
      margin: 4.5rem auto 0; }
      @media (max-width: 1024px) {
        .article__main__content {
          margin-top: 0;
          padding: 1rem; } }
  .article__sidebar {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    width: 360px;
    height: 100%;
    padding: 2rem;
    border-left: solid rgba(34, 36, 38, 0.15) 1px;
    box-shadow: -1px 0px 3px rgba(34, 36, 38, 0.15);
    background-color: #fff;
    transition: opacity 1s;
    will-change: opacity;
    opacity: 1; }
    @media (max-width: 1024px) {
      .article__sidebar {
        width: 0;
        padding: 0;
        opacity: 0; } }
    .article__sidebar__menu__tab {
      margin: -2rem -24px;
      padding: 2rem 24px;
      overflow: auto;
      height: 100%;
      max-height: calc(100% - 17rem); }
    .article__sidebar--visible {
      padding: 2rem;
      opacity: 1; }
      @media (max-width: 1024px) {
        .article__sidebar--visible {
          width: 360px; } }
      @media (max-width: 425px) {
        .article__sidebar--visible {
          width: 85vw; } }
      .article__sidebar--visible .ui.pointing.menu {
        overflow-x: auto;
        overflow-y: hidden; }
  .article__sidebar-backdrop {
    top: 0;
    left: 0;
    content: '';
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
    opacity: 0;
    transition: opacity 1s;
    will-change: opacity;
    z-index: 999; }
    .article__sidebar-backdrop--visible {
      position: absolute;
      height: 100%;
      opacity: 1; }
      @media (max-width: 1024px) {
        .article__sidebar-backdrop--visible {
          width: calc(100% - 360px + 1px); } }
      @media (max-width: 425px) {
        .article__sidebar-backdrop--visible {
          width: calc(100% - 85vw + 1px); } }
    .article__sidebar-backdrop__close-button.ui.button.icon {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #fff;
      color: #000;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin: 0; }
