@font-face {
  font-family: 'IcoMoon-Free';
  src: url("/fonts/IcoMoon-Free.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.isHidden {
  display: none !important; }

.ui.selection.dropdown > .icon.dropdown {
  margin-top: -0.65em !important; }

.ui.form .equal.width.fields {
  flex-wrap: wrap;
  padding-bottom: 0.5rem !important;
  margin-bottom: 0 !important; }
  .ui.form .equal.width.fields .field {
    width: auto !important;
    margin-bottom: 0.5rem !important; }

.ui.form + .message {
  margin-top: 0; }

.ui.menu .item .ui.label:first-child {
  margin-left: 0 !important; }

.ui.inverted.popup.red {
  background: #db2828; }
  .ui.inverted.popup.red::before {
    background: #db2828; }

.ui.modal > .close {
  top: 0.8rem;
  right: 0.8rem;
  width: 3rem;
  height: 3rem;
  padding: 0;
  color: rgba(0, 0, 0, 0.6);
  line-height: 3rem;
  margin: 0; }
  .ui.modal > .close:hover {
    color: rgba(0, 0, 0, 0.8); }
  .ui.modal > .close:active {
    background-color: #f8f8f8; }

.ui.fluid.labeled.icon.button {
  padding-left: 36px !important; }

.ui.segment.error {
  border-color: #e0b4b4 !important; }

.daterangepicker {
  display: none;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  margin-top: 10px; }
  .daterangepicker.opensright:before {
    left: 12px; }
  .daterangepicker.opensright:after {
    left: 13px; }
  .daterangepicker .range_inputs {
    display: flex; }
    .daterangepicker .range_inputs .btn {
      flex: 1;
      height: 40px;
      padding: 4px 12px;
      font-size: 13px;
      font-weight: bold;
      border: none;
      border-radius: 4px;
      box-shadow: none;
      outline: none; }
    .daterangepicker .range_inputs .btn + .btn {
      margin-left: 8px; }
    .daterangepicker .range_inputs .applyBtn {
      color: #fff;
      background-color: #21ba45; }
      .daterangepicker .range_inputs .applyBtn:hover {
        background-color: #16ab39; }
      .daterangepicker .range_inputs .applyBtn:active {
        background-color: #198f35; }
    .daterangepicker .range_inputs .cancelBtn {
      color: rgba(0, 0, 0, 0.6);
      background-color: #e0e1e2; }
      .daterangepicker .range_inputs .cancelBtn:hover {
        background-color: #cacbcd; }
      .daterangepicker .range_inputs .cancelBtn:active {
        background-color: #babbbc; }
  .daterangepicker .glyphicon {
    font-family: Icons;
    font-style: normal; }
  .daterangepicker .glyphicon-calendar:before {
    content: '\f133'; }
  .daterangepicker .glyphicon-chevron-left:before {
    content: '\f060'; }
  .daterangepicker .glyphicon-chevron-right:before {
    content: '\f061'; }
  .daterangepicker .daterangepicker_input input {
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: none; }
    .daterangepicker .daterangepicker_input input.active {
      border-color: #85b7d9; }
  .daterangepicker .daterangepicker_input i {
    top: 6px;
    left: 9px; }
  .daterangepicker .calendar-table {
    border: 1px solid #ddd; }
    .daterangepicker .calendar-table tr {
      height: 30px;
      line-height: 28px; }

.custom-scroll-bar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5; }

.custom-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #999; }

.custom-scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #f5f5f5; }
